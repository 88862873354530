import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchsDocument } from '../../store/action/DocumentAction';
import Layout from '../Layout';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import TableDocument from '../Table/TableDocument';
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const DocumentList = () => {
  const dispatch = useDispatch();
  const { documents, countRow } = useSelector((state: any) => state.documents);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    dispatch(FetchsDocument(page, rowsPerPage, setOpen));
  }, [dispatch, page, rowsPerPage]);
  const onChangePage = async (event: any, newPage: number) => {
    setOpen(true);
    dispatch(FetchsDocument(newPage, rowsPerPage, setOpen));
    setPage(newPage);
  };
  const onChangeRowPerPage = (event: any) => {
    setOpen(true);
    dispatch(FetchsDocument(0, event.target.value, setOpen));
    setPage(0);
    setRowsPerPage(+event.target.value);
  };
  return (
    <Layout>
      <TableDocument
        count={countRow}
        documents={documents}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePages={onChangePage}
        onChangeRowPerPage={onChangeRowPerPage}
      />
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress variant='indeterminate' />
      </Backdrop>
    </Layout>
  );
};

export default DocumentList;
