import React from 'react';
import LoginForm from '../Form/LoginForm';
import { Grid } from '@material-ui/core';
interface LoginInterface {
  setRole: Function;
}
const Login: React.FC<LoginInterface> = ({ setRole }) => {
  return (
    <div>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <LoginForm setRole={setRole} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
