import jwtDecode from "jwt-decode"
const setToken = (token:any) => {
    localStorage.setItem("ACCESS_TOKEN",token)
}
const getToken:any = () => {
   return localStorage.getItem('ACCESS_TOKEN')
}
const removeToken = () => {
    localStorage.removeItem('ACCESS_TOKEN')
}
const getRole = () => {
    if (getToken()) {
        const token = getToken()
        const dataJWT:any = jwtDecode(token)
        return dataJWT.role
    }
    return "guest"
}
const FuncToken = {
    getToken,
    getRole,
    setToken,
    removeToken
}
export default  FuncToken