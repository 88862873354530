import React from 'react'
import {Switch , Redirect , Route } from 'react-router-dom'
import ConfigRoute from './Route'
interface PrivateObject {
    role:string,
    setRole:any
}
const PrivateRoutes:React.FC<PrivateObject> = (props) => {
    let role = props.role || "guest"
    const allowRoutes = ConfigRoute[role].allowRoutes
    const redirectRoutes = ConfigRoute[role].redirectRoutes
    return (
        <Switch>
            {
                allowRoutes.map((route:any)=>
                    <Route path={route.url} key={route.url} exact>
                        <route.component role={role} setRole ={props.setRole} />
                    </Route>
                )
            }
            <Redirect  to={redirectRoutes}/>
        </Switch>
    )
}

export default PrivateRoutes
