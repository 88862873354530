import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import Qrcode from 'qrcode.react';
import { Send, CheckOutlined } from '@material-ui/icons';
import Header from '../Header';
import { useHistory } from 'react-router-dom';
const ViewQrcode = () => {
  const history: any = useHistory();
  let { id }: any = useParams();
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (history.location.state?.status === undefined) history.push('/');
    let generateURL = generateUrl(id);
    setUrl(generateURL);
  }, [id, history]);
  const generateUrl = (name: string) => {
    const document = {
      type: 'DOCUMENT',
      payload: {
        uri: `https://api.dev.vp.evernetwork.io/v1/vaccine/${name}`,
        permittedActions: ['STORE', 'VIEW'],
        redirect: 'https://dev.tradetrust.io/',
      },
    };
    const strDocument = JSON.stringify(document);
    const url = encodeURIComponent(strDocument);
    return url;
  };
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Header />
      </Grid>
      <Grid item container xs={4} />
      <Grid
        item
        container
        xs={4}
        justifyContent='center'
        style={{ backgroundColor: 'white', borderRadius: 10, marginTop: 50, textAlign: 'center' }}
      >
        <Grid item xs={12} container justifyContent='center'>
          <div>
            <CheckOutlined
              style={{ color: 'white', backgroundColor: 'green', borderRadius: '50%', fontSize: 50, marginTop: 20 }}
            />
            <h2 style={{ color: 'green', textAlign: 'center' }}>Approved</h2>
            <p>
              Your request has been approved and is being published on blockchain. Please wait an hour before scanning
              the QR code.
            </p>
            <Qrcode
              value={`https://verify2.dev.vp.evernetwork.io?q=${url}`}
              size={170}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'L'}
              includeMargin={false}
              renderAs={'svg'}
            />
            <br />
            <Button
              variant='contained'
              style={{ margin: 20 }}
              color='primary'
              endIcon={<Send />}
              onClick={() => window.open(`https://verify2.dev.vp.evernetwork.io?q=${url}`, '_blank')}
            >
              Click Link
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default ViewQrcode;
