import {FETCHS_DOCUMENTS,FETCH_DOCUMENT} from '../action/Type'

const InitialState = {
    countRow:0,
    documents:[]
}
const DocumentReducer = (state = InitialState , action:any) => {
 switch (action.type) {
     case FETCHS_DOCUMENTS:
         return {documents:[...action.payload.document],
             countRow:action.payload.countRow
            }
         ;
    case FETCH_DOCUMENT:
        return action.payload
     default:
         return state;
 }
}
export default DocumentReducer