import React, { useState } from 'react';
import { TextField, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import LayoutVaccineForm from './LayoutVaccineForm';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    width: '60%',
  },
}));
const TestCertificateForm = () => {
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      SpecimenType: '',
      SpecimenDate: '',
      TestType: '',
      TestDate: '',
      TestResult: '',
      TestPerformer: '',
      TestPerformerID: '',
      ProviderType: '',
      ProviderAddress: '',
      ProviderName: '',
      ProviderPhone: '',
      LabType: '',
      LabAddress: '',
      LabName: '',
      LabPhone: '',
    },
  ]);
  const [simple, setSimple] = useState({});

  const handleChangeInput = (id: any, event: any) => {
    const newInputFields = inputFields.map((i: any) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        SpecimenType: '',
        SpecimenDate: '',
        TestType: '',
        TestDate: '',
        TestResult: '',
        TestPerformer: '',
        TestPerformerID: '',
        ProviderName: '',
        ProviderType: '',
        ProviderAddress: '',
        ProviderPhone: '',
        LabType: '',
        LabAddress: '',
        LabName: '',
        LabPhone: '',
      },
    ]);
  };

  const handleRemoveFields = (id: any) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFields(values);
  };
  const onSimpleData = () => {
    let setData = inputFields.map((item: any) => {
      return {
        id: uuidv4(),
        SpecimenType: ' Nasopharyngeal swab',
        SpecimenDate: '2020-09-27',
        TestType: 'Reverse transcription polymerase chain reaction (rRT-PCR) test',
        TestDate: '2020-09-28',
        TestResult: 'Negative',
        TestPerformer: 'Dr. Somchai Chaiyut',
        TestPerformerID: '12345',
        ProviderType: ' Licensed Healthcare Provider',
        ProviderAddress: ' 123 Ever Road Bangkok 10900',
        ProviderName: 'Ever Clinic',
        ProviderPhone: '020001111',
        LabType: ' Accredited Laboratory',
        LabAddress: '124 Ever Road Bangkok 10900',
        LabName: ' Ever Laboratories',
        LabPhone: '020001111',
      };
    });
    setInputFields(setData);
    setSimple({
      namePerson: 'David Zhang',
      NationalID: 'S9098989Z',
      PassportID: 'E7831177G',
      Nationality: 'Singaporean',
      dob: '2020-07-13',
      Gender: 'male',
      phone: '0814206492',
      email: 'jakkaphan@everapp.io',
    });
  };
  return (
    <LayoutVaccineForm vaccineField={inputFields} SimpleData={onSimpleData} PatientData={simple} FormType='RT-PCR'>
      <Grid container className={classes.root} direction='row'>
        <Grid item xs={3}>
          <h3 style={{ color: '#325288' }}>Test Details </h3>
        </Grid>
        <Grid item xs={9} style={{ marginTop: 80 }}>
          {inputFields.map(inputField => (
            <Grid container direction='column' justifyContent='center' item xs={8} key={inputField.id}>
              <Grid container direction='row'>
                <Grid item xs={10}>
                  <h4 style={{ color: '#9BADC8' }}>Test detail*</h4>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={handleAddFields} style={{ backgroundColor: '#FFA900' }}>
                    <Add style={{ color: 'white' }} />
                  </IconButton>
                </Grid>
              </Grid>

              <TextField
                name='SpecimenType'
                label='Specimen Type'
                variant='outlined'
                value={inputField.SpecimenType}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='SpecimenDate'
                label='Specimen Collection Date Time'
                type='date'
                variant='outlined'
                value={inputField.SpecimenDate}
                onChange={event => handleChangeInput(inputField.id, event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name='TestType'
                label='Test Type'
                variant='outlined'
                value={inputField.TestType}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='TestDate'
                label='Test Date Time'
                variant='outlined'
                type='date'
                value={inputField.TestDate}
                onChange={event => handleChangeInput(inputField.id, event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name='TestResult'
                label='Test Result'
                variant='outlined'
                value={inputField.TestResult}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='TestPerformer'
                label='Test Performer'
                variant='outlined'
                value={inputField.TestPerformer}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='TestPerformerID'
                label='Test Performer ID'
                variant='outlined'
                value={inputField.TestPerformerID}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ProviderName'
                label='Provider Name'
                variant='outlined'
                value={inputField.ProviderName}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ProviderType'
                label='Provider Type'
                variant='outlined'
                value={inputField.ProviderType}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ProviderAddress'
                label='Provider Address'
                variant='outlined'
                value={inputField.ProviderAddress}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ProviderPhone'
                label='Provider Contact Number'
                variant='outlined'
                value={inputField.ProviderPhone}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='LabName'
                label='Lab Name'
                variant='outlined'
                value={inputField.LabName}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='LabType'
                label='Lab Type'
                variant='outlined'
                value={inputField.LabType}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='LabAddress'
                label='Lab Address'
                variant='outlined'
                value={inputField.LabAddress}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='LabPhone'
                label='Lab Contact Number'
                variant='outlined'
                value={inputField.LabPhone}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <IconButton disabled={inputFields.length === 1} onClick={() => handleRemoveFields(inputField.id)}>
                <Delete color='secondary' />
              </IconButton>
              <hr />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </LayoutVaccineForm>
  );
};

export default TestCertificateForm;
