import React from 'react';
import { Divider, Grid, Hidden } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import Header from './Header';

const Error = () => {
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Header />
      </Grid>
      <Grid item container xs={3} />
      <Grid
        item
        container
        xs={6}
        style={{ backgroundColor: 'whitesmoke', marginTop: 90, borderRadius: 10, height: 320, textAlign: 'center' }}
      >
        <Hidden smDown>
          <Grid item xs={6} container>
            <h1 style={{ fontSize: 100, color: '#BD2000', textAlign: 'center', marginLeft: 50, marginTop: 80 }}>400</h1>
            <Divider variant='fullWidth' orientation='vertical' style={{ marginLeft: 20 }} />
          </Grid>
          <Grid item xs={6} container>
            <div>
              <HighlightOff
                style={{ backgroundColor: '#BD2000', borderRadius: '50%', fontSize: 70, marginTop: 45, color: 'white' }}
              />
              <h2>ERROR</h2>
              <h2>BAD REQUEST</h2>
            </div>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <h1 style={{ color: '#BD2000', textAlign: 'center', marginLeft: 50, marginTop: 80 }}>400</h1>
          <div>
            <HighlightOff
              style={{ backgroundColor: '#BD2000', borderRadius: '50%', fontSize: 40, marginTop: 10, color: 'white' }}
            />
            <h2>ERROR</h2>
            <h2>BAD REQUEST</h2>
          </div>
        </Hidden>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default Error;
