import React, { FC, useState, useEffect } from 'react';
import { VaccineCertificate, RTPCRCertificate } from '../Json/FormatJson';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import axios from '../../Config/axios';
import LocalStorageService from '../../LocalStorageService/LocalStorageService';
// import { v2 } from '@govtechsg/open-attestation';

// // components
// import { DocumentPreview } from '../DocumentPreview';

// mui
import {
  Container,
  TextField,
  Button,
  Divider,
  Grid,
  NativeSelect,
  InputBase,
  Step,
  StepLabel,
  Stepper,
  Backdrop,
  CircularProgress,
  // FormControlLabel,
  // Switch,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Send } from '@material-ui/icons';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    margin: 7,
    padding: '15px 20px 20px 20px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    width: '60%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
interface LayoutInterFace {
  vaccineField: Array<Object>;
  SimpleData: Function;
  PatientData: any;
  FormType: string;
}

const LayoutVaccineForm: FC<LayoutInterFace> = ({ children, vaccineField, SimpleData, PatientData, FormType }) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [namePerson, setNamePerson] = useState('');
  const [NationalID, setNationalID] = useState('');
  const [PassportID, setPassportID] = useState('');
  const [Nationality, setNationality] = useState('');
  const [dob, setDOB] = useState('');
  const [Gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [confirmMail, setConfirmMail] = useState('');
  const steps: Array<string> = ['Choose Document Type ', 'Fill Form', 'Submit Form'];
  const [active, setActive] = useState(1);
  // const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);

  // useEffect(() => {
  //   Preview();
  // }, [SimpleData]);

  useEffect(() => {
    if (PatientData.namePerson) {
      setNamePerson(PatientData.namePerson);
      setNationalID(PatientData.NationalID);
      setPassportID(PatientData.PassportID);
      setNationality(PatientData.Nationality);
      setDOB(PatientData.dob);
      setGender(PatientData.Gender);
      setPhone(PatientData.phone);
      setEmail(PatientData.email);
    }
  }, [PatientData]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setActive(2);
    setOpen(true);
    let token = LocalStorageService.getToken();
    let { _id }: any = jwtDecode(token);
    let data = {
      Nationality,
      dob,
      NationalID,
      Gender,
      PassportID,
      namePerson,
    };
    let dataSendmail = {
      ...data,
      recieveEmail: email,
      phone,
      fullname: namePerson,
      usernameID: _id,
    };
    if (FormType === 'vaccine') {
      let jsonVaccine = VaccineCertificate(vaccineField, data);
      axios
        .post('/v1/vaccine/createethdocument', {
          ...jsonVaccine,
          data: { ...dataSendmail, docName: 'COVID-19 Vaccine Certificate', vaccineCertificate: vaccineField },
        })
        .then((result: any) => {
          history.push(`/success`);
        })
        .catch(err => {
          history.push('/errorpage');
        });
      return;
    }
    let rtpcrJSON = RTPCRCertificate(vaccineField, data);
    axios
      .post('/v1/vaccine/createethdocument', {
        ...rtpcrJSON,
        data: { ...dataSendmail, docName: 'COVID-19 RT-PCR Test Certificate', PCRCertificate: vaccineField },
      })
      .then((result: any) => {
        history.push(`/success`);
      })
      .catch(err => {
        history.push('/errorpage');
      });
  };

  // const convertDataFormat = () => {
  //   let data = {
  //     Nationality,
  //     dob,
  //     NationalID,
  //     Gender,
  //     PassportID,
  //     namePerson,
  //   };

  //   if (FormType === 'vaccine') {
  //     return VaccineCertificate(vaccineField, data) as v2.OpenAttestationDocument;
  //   } else {
  //     return RTPCRCertificate(vaccineField, data) as v2.OpenAttestationDocument;
  //   }
  // };

  // const Preview = () => {
  //   return <DocumentPreview document={convertDataFormat()} />;
  // };

  const onChangeNamePerson = (e: any) => setNamePerson(e.target.value);
  const onChangeNationID = (e: any) => setNationalID(e.target.value);
  const onChangePassportID = (e: any) => setPassportID(e.target.value);
  const onChangeNationality = (e: any) => setNationality(e.target.value);
  const onChangeDob = (e: any) => setDOB(e.target.value);
  const onChangeGender = (e: any) => setGender(e.target.value);
  const onChangePhone = (e: any) => setPhone(e.target.value);
  const onChangeEmail = (e: any) => setEmail(e.target.value);
  const onChangeConfirmEmail = (e: any) => setConfirmMail(e.target.value);
  const onCloseBackdrop = () => {
    setOpen(!open);
  };
  return (
    <Container style={{ backgroundColor: 'white', borderColor: 'gray' }}>
      <div style={{ backgroundColor: '#F2F5FB', height: '10vh' }}>
        <Stepper activeStep={active} alternativeLabel>
          {steps.map((step: string) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <Grid container direction='row' justifyContent='flex-end'>
          <Button
            style={{ backgroundColor: '#FFA900', marginTop: 80 }}
            variant='contained'
            onClick={() => SimpleData()}
          >
            Sample Data
          </Button>
        </Grid>

        {/* <Grid container justifyContent='center' style={{ marginTop: '1rem' }}>
          <FormControlLabel
            control={
              <Switch color='primary' checked={isPreviewMode} onChange={() => setIsPreviewMode(!isPreviewMode)} />
            }
            label='Preview mode'
            labelPlacement='start'
          />
        </Grid> */}

        {/* {isPreviewMode ? (
          <div style={{ maxWidth: '1280px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1.5rem' }}>
            <Preview />
          </div>
        ) : (
          <> */}
        <Grid container className={classes.root} direction='row'>
          <Grid item xs={3}>
            <h3 style={{ color: '#325288' }}>Patient Detail *</h3>
          </Grid>
          <Grid item xs={9} style={{ marginTop: 80 }}>
            <Grid container direction='column' justifyContent='center' item sm={8}>
              <TextField
                name='namePerson'
                label='Name of Person :'
                variant='outlined'
                value={namePerson}
                onChange={onChangeNamePerson}
              />
              <TextField
                name='NationalID'
                label='National ID Number :'
                variant='outlined'
                value={NationalID}
                onChange={onChangeNationID}
              />
              <TextField
                name='PassportID'
                label='Passport/Travel Document Number :'
                variant='outlined'
                value={PassportID}
                onChange={onChangePassportID}
              />
              <TextField
                name='Nationality'
                label='Nationality/Citizenship :'
                variant='outlined'
                value={Nationality}
                onChange={onChangeNationality}
              />
              <TextField
                name='DOB'
                label='Birthday'
                variant='outlined'
                value={dob}
                type='date'
                onChange={onChangeDob}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <NativeSelect
                id='demo-simple-select-helper'
                value={Gender}
                onChange={onChangeGender}
                input={<BootstrapInput />}
              >
                <option>Male</option>
                <option>Female</option>
              </NativeSelect>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20 }} />
        {children}
        <Divider />
        <Grid container className={classes.root} direction='row'>
          <Grid item xs={3}>
            <h3 style={{ color: '#325288' }}>Patient Contact Detail *</h3>
          </Grid>
          <Grid item xs={9}style={{ marginTop: 80 }}>
            <Grid container direction='column' justifyContent='center' item sm={8}>
              <TextField
                name='PhoneNumber'
                label='Phone number :'
                variant='outlined'
                value={phone}
                onChange={onChangePhone}
              />
              <TextField name='Email' label='Email :' variant='outlined' value={email} onChange={onChangeEmail} />
              {email !== confirmMail && <b style={{ color: 'red' }}>invalid Email or Confirm Email</b>}
              <TextField
                name='Email'
                label='Confirm Email :'
                variant='outlined'
                value={confirmMail}
                onChange={onChangeConfirmEmail}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Button
              disabled={
                email !== confirmMail ||
                [email, confirmMail, phone, Gender, dob, Nationality, PassportID, NationalID, namePerson].includes('')
              }
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
              endIcon={<Send />}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <div>
        <Backdrop open={open} onClick={() => onCloseBackdrop()} className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </Container>
  );
};

export default LayoutVaccineForm;
