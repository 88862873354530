import axios from '../../Config/axios';
import {USER_LOGIN} from './Type'
import jwtDecode from 'jwt-decode';
import LocalStorageService from '../../LocalStorageService/LocalStorageService'
export const UserLogin = (body:Object,setRole:Function) => {
    return async(dispatch:any) => {
        try {       
          let result = await axios.post('/user/login',body)
            let user:any = jwtDecode(result.data.token)
            LocalStorageService.setToken(result.data.token)
            setRole(user.role)
            dispatch({type:USER_LOGIN,payload:user})
        } catch (error) {
            alert("Login Fail")
        }
    }
}
