import axios from 'axios';
import LocalStorageService from '../LocalStorageService/LocalStorageService';
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(
    (config:any)=>{
        if(config.url.includes("/user/login"))return config
        const token = LocalStorageService.getToken();
        if(token){
            config.headers["Authorization"] = `Bearer ${token}`
        }
        return config
    },
    err=>{
        Promise.reject(err)
    }
)
axios.interceptors.response.use(
    //will get respone and error from server
    response =>{
        return response
    },
    err=>{
        if (err.response?.status === 401) {
            LocalStorageService.removeToken();
            window.location.reload();
            return Promise.reject(err)

        }
        if (err.response?.status === 403) {
            window.location.replace('/')            
            return Promise.reject(err)
        }
        return Promise.reject(err)
    }
)
export default axios