export const VaccineCertificate = (vaccineField: Array<Object>, data: any) => {
  let Location = vaccineField.map((item: any) => {
    return {
      fullUrl: 'urn:uuid:eec4dd98-bf14-4706-90c3-a6e4e2a82e24',
      resourceType: 'Location',
      id: item.ClinicID,
      locationName: item.ClinicName,
      address: {
        country: item.Country,
      },
    };
  });
  let Immunization = vaccineField.map((item: any) => {
    return {
      fullUrl: 'urn:uuid:fd332518-562a-4387-813b-3156cd9b5b88',
      resourceType: 'Immunization',
      vaccineCode: {
        coding: [
          {
            system: '',
            code: item.Code,
            display: item.Name,
          },
        ],
      },
      lotNumber: item.BatchId,
      occurrenceDateTime: item.DateVaccine,
      patient: {
        reference: 'urn:uuid:fbe6941e-f59b-4bca-a364-f453c9a35cb6',
      },
      location: {
        reference: 'urn:uuid:eaba1b95-8db1-4338-a784-41c990dee911',
      },
      performer: [
        {
          actor: {
            display: item.Health,
          },
        },
      ],
    };
  });

  let jsonVaccine = {
    id: '6502e7bf-8673-4569-990e-d31b6117097b',
    name: 'covid-19-vaccine-certificate',
    validFrom: '2021-06-24T10:10:59.587Z',
    fhirVersion: '1.0.0',
    fhirBundle: {
      resourceType: 'Bundle',
      type: 'collection',
      entry: [
        {
          fullUrl: 'urn:uuid:fa2363b9-641f-4e67-b5d0-afa3030d0f25',
          resourceType: 'Patient',
          extension: [{ url: '', code: { text: data.Nationality } }],
          identifier: [
            { type: { text: 'NRIC' }, value: data.NationalID },
            { type: 'PPN', value: data.PassportID },
          ],
          name: [{ text: data.namePerson }],
          gender: data.Gender,
          birthDate: data.dob,
        },
        ...Location,
        ...Immunization,
      ],
    },
    issuers: [
      {
        name: 'Ever2',
        documentStore: '0x6E2047f8fB852Bda8cc63882bc79E233F00547A5',
        identityProof: {
          type: 'DNS-TXT',
          location: 'dev.vp.evernetwork.io',
        },
      },
    ],
    $template: {
      name: 'main',
      type: 'EMBEDDED_RENDERER',
      url: 'https://renderer.dev.vp.evernetwork.io',
    },
    notarisationMetadata: {
      reference: '0ae14870-351b-41a6-893b-3a3205fc4d25',
      notarisedOn: '2021-06-24T10:10:59.587Z',
      passportNumber: 'E7831177G',
      url: 'https://verify.dev.vp.evernetwork.io/?q=%7B%22type%22%3A%22DOCUMENT%22%2C%22payload%22%3A%7B%22uri%22%3A%22https%3A%2F%2Fever-medical-technologies.gitlab.io%2Fmoph-ict%2Fvaccine-passport%2Fdemo%2Feth-default.json%22%2C%22permittedActions%22%3A%5B%22VIEW%22%2C%22STORE%22%5D%2C%22redirect%22%3A%22https%3A%2F%2Fdev.tradetrust.io%2F%22%7D%7D',
    },
    logo: 'data:image/png;base64,---',
  };
  return jsonVaccine;
};
export const RTPCRCertificate = (vaccineField: Array<Object>, data: any) => {
  let RTPCRField = vaccineField.map((item: any) => {
    return [
      {
        resourceType: 'Specimen',
        type: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '258500001',
              display: item.SpecimenType,
            },
          ],
        },
        collection: { collectedDateTime: item.SpecimenDate },
      },
      {
        resourceType: 'Observation',
        identifier: [{ value: '123456789', type: 'ACSN' }],
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '94531-1',
              display: item.TestType,
            },
          ],
        },
        valueCodeableConcept: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '260385009',
              display: item.TestResult,
            },
          ],
        },
        effectiveDateTime: item.TestDate,
        status: 'final',
        performer: { name: [{ text: item.TestPerformer }] },
        qualification: [{ identifier: 'MCR 123214', issuer: 'MOH' }],
      },
      {
        resourceType: 'Organization',
        name: item.ProviderName,
        type: item.ProviderType,
        endpoint: { address: 'https://www.macritchieclinic.com.sg' },
        contact: {
          telecom: [{ system: 'phone', value: item.ProviderPhone }],
          address: {
            type: 'physical',
            use: 'work',
            text: item.ProviderAddress,
          },
        },
      },
      {
        resourceType: 'Lab',
        name: item.LabName,
        type: item.LabType,
        contact: {
          telecom: [{ system: 'phone', value: item.LabPhone }],
          address: {
            type: 'physical',
            use: 'work',
            text: item.LabAddress,
          },
        },
      },
    ];
  });
  let rtpcrJSON = {
    id: '65b57974-6e91-4b9c-826d-1d15ed4c4694',
    name: 'HealthCert',
    validFrom: '2020-11-20T06:43:12.152Z',
    fhirVersion: '4.0.1',
    fhirBundle: {
      resourceType: 'Bundle',
      type: 'collection',
      entry: [
        {
          fullUrl: '57658d7e-7905-4115-8e76-02c220ea0901:string:urn:uuid:fa2363b9-641f-4e67-b5d0-afa3030d0f25',
          resourceType: 'Patient',
          extension: [
            {
              url: 'dcf397cd-d536-49e7-b326-3a0c1aaf5616:string:',
              code: {
                text: data.Nationality,
              },
            },
          ],
          identifier: [
            {
              type: {
                text: 'NRIC',
              },
              value: data.NationalID,
            },
            {
              type: 'PPN',
              value: data.PassportID,
            },
          ],
          name: [
            {
              text: data.namePerson,
            },
          ],
          gender: data.Gender,
          birthDate: data.dob,
        },
        ...RTPCRField.flat(),
      ],
    },
    issuers: [
      {
        name: 'Ever2',
        documentStore: '0x6E2047f8fB852Bda8cc63882bc79E233F00547A5',
        identityProof: {
          type: 'DNS-TXT',
          location: 'dev.vp.evernetwork.io',
        },
      },
    ],
    $template: {
      name: 'pcr',
      type: 'EMBEDDED_RENDERER',
      url: 'https://renderer.dev.vp.evernetwork.io',
    },
    notarisationMetadata: {
      reference: '65b57974-6e91-4b9c-826d-1d15ed4c4694',
      notarisedOn: '2021-07-06T05:44:00.588Z',
      passportNumber: 'E7831177G',
      url: 'https://action.openattestation.com/?q=%7B%22type%22%3A%22DOCUMENT%22%2C%22payload%22%3A%7B%22uri%22%3A%22https%3A%2F%2Fapi-vaccine.storage.staging.notarise.io%2Fdocument%2Fd4af23cd-d537-4e61-8152-038c5977747a%22%2C%22key%22%3A%228878f95364ba6cafecf3f23e6f3325067b8c6e54f1d07e897ac3132a2e4b1b78%22%2C%22permittedActions%22%3A%5B%22VIEW%22%2C%22STORE%22%5D%2C%22redirect%22%3A%22https%3A%2F%2Fwww.verify.gov.sg%2Fverify%22%7D%7D',
    },
    logo: 'data:image/png;base64,------',
  };
  return rtpcrJSON;
};
