import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  makeStyles,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  Button,
} from '@material-ui/core';
import {  Restore, CheckBox, HighlightOff, LibraryAddCheck } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
// import jwtDecode from 'jwt-decode';
// import LocalStorageService from '../../LocalStorageService/LocalStorageService';
interface DataInTable {
  documents: Array<Object>;
  rowsPerPage: number;
  count: number;
  page: number;
  onChangePages: Function;
  onChangeRowPerPage: Function;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
const TableDocument: React.FC<DataInTable> = ({
  documents,
  rowsPerPage,
  count,
  onChangePages,
  page,
  onChangeRowPerPage,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const onSettingDate = (date: string) => {
    const options: Object = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let day = new Date(date).toLocaleDateString('en-US', options);
    let time = new Date(date).toLocaleTimeString('en-US');
    return date ? `${day} ${time}` : '---';
  };
  const onShowStatus = (status: string) => {
    return status ? (
      status === 'PENDING' ? (
        <Restore color='primary' style={{ fontSize: 30 }} />
      ) : status === 'ISSUED' ? (
        <CheckBox style={{ color: 'green', fontSize: 30 }} />
      ) : (
        <HighlightOff style={{ color: '#BD2000', fontSize: 30 }} />
      )
    ) : (
      '---'
    );
  };

  const onApprove = (id: string , status = "APPROVED") => {
    if(status === "APPROVED") return history.push(`/documents/qrcode/${id}`, { status: status })
    history.push('/documents/preview/' + id);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='custom pagination table'>
        <TableBody>
          <TableRow>
            <TableCell align='center' variant='head'>
              <h3>PATIENT NAME</h3>
            </TableCell>
            <TableCell align='center' variant='head'>
              <h3>CATEGORY DOCUMENT</h3>
            </TableCell>
            <TableCell align='center' variant='head'>
              <h3>DATE CREATED</h3>
            </TableCell>
            <TableCell align='center' variant='head'>
              <h3>ISSUANCE STATUS</h3>
            </TableCell>
            <TableCell align='center' variant='head'>
              <h3>APPROVE STATUS</h3>
            </TableCell>
            <TableCell align='center' variant='head'>
              <h3>ACTION</h3>
            </TableCell>
          </TableRow>
          {Array.isArray(documents) &&
            documents.map((document: any) => (
              <TableRow key={document._id}>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  {document.patienName}
                </TableCell>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  {document.categoryDoc === 'main'
                    ? 'COVID-19 Vaccine Certificate'
                    : 'COVID-19 RT-PCR Test Certificate'}
                </TableCell>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  {onSettingDate(document.createDate)}
                </TableCell>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  {onShowStatus(document.issuanceStatus)}
                </TableCell>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  {document?.status}
                </TableCell>
                <TableCell style={{ width: 100 }} align='center' variant='body'>
                  <Button
                    onClick={() => onApprove(document.codeID,document.status)}
                    endIcon={<LibraryAddCheck />}
                    variant='contained'
                    color='primary'
                  >
                    Check Document
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={4}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={(e, pageNumber) => onChangePages(e, pageNumber)}
              onRowsPerPageChange={event => onChangeRowPerPage(event)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TableDocument;
