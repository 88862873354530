import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, Step, StepLabel, Stepper, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../Layout';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F2F5FB',
    height: '90vh',
  },
  paper: {
    height: 120,
    width: 200,
    marginTop: 30,
    textAlign: 'center',
    backgroundColor: 'whitesmoke',
    cursor: 'pointer',
  },
  control: {
    padding: theme.spacing(2),
  },
}));
const DocumentType = () => {
  const history = useHistory();
  const classes = useStyles();
  const doctype = [
    { name: 'COVID-19 Vaccine Certificate', url: '/certificate' },
    { name: 'COVID-19 RT-PCR Test Certificate', url: '/testcertificate' },
  ];
  const steps: Array<string> = ['Choose Document Type ', 'Fill Form', 'Submit Form'];
  return (
    <Layout>
      <Grid container className={classes.root} spacing={2}>
        <Grid item  sm={1} md={1} lg={1} />
        <Grid
          item
          sm={10}
          md={10}
          lg={10}
          style={{ marginTop: 100, height: 600, backgroundColor: 'white', borderRadius: 15 }}
        >
          <Grid container justifyContent='center' style={{ marginTop: '60px' }}>
            <Hidden xsDown>
              <h1 style={{ color: '#9BADC8' }}>Choose Document Type to Issue</h1>
            </Hidden>
            <Hidden smUp>
              <h2 style={{ color: '#9BADC8' }}>Choose Document Type to Issue</h2>
            </Hidden>
          </Grid>
          <Grid container justifyContent='center' spacing={5}>
            {doctype.map(value => (
              <Grid key={value.url} item>
                <Paper className={classes.paper} onClick={() => history.push(value.url)}>
                  <h3 style={{ paddingTop: 30, color: '#9BADC8' }}>{value.name}</h3>
                </Paper>
              </Grid>
            ))}

          </Grid>
          <Grid style={{marginTop:'90px'}} >
            <Stepper activeStep={0} alternativeLabel>
              {steps.map((step: string) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DocumentType;
