import {FETCHS_DOCUMENTS , FETCH_DOCUMENT} from './Type'
import axios from '../../Config/axios';

export const FetchsDocument = (pageNumber:number,nPerPage:number,setOpen:Function) => {
    return (dispatch:any) => {
        axios.get(`/v1/vaccine/?pageNumber=${pageNumber}&nPerPage=${nPerPage}`)
            .then((result) => {
                dispatch({type:FETCHS_DOCUMENTS,payload:result.data})
                setOpen(false)
            }).catch((err) => {
                alert('Fetch documents fail')
            });
    }
}
export const FetchDocumnet = (id:string) => {
    return (dispatch:any) => {
        axios.get(`/v1/vaccine/${id}`)
            .then((result) => {
                dispatch({type:FETCH_DOCUMENT,payload:result.data})
            }).catch((err) => {
                alert("Fetch document fail")
            });
    }
}
