import {USER_LOGIN} from '../action/Type'
const UserReducer = (state={},action:any) => {
    switch (action.type) {
        case USER_LOGIN:
            return action.payload
    
        default:
            return state;
    }
}
export default UserReducer