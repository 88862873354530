import React, { useState } from 'react';
import { TextField, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import LayoutVaccineForm from './LayoutVaccineForm';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    width: '60%',
  },
}));
const CertificateForm = () => {
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      Code: '',
      Name: '',
      ClinicID: '',
      ClinicName: '',
      DateVaccine: '',
      BatchId: '',
      Country: '',
      Health: '',
    },
  ]);
  const [simple, setSimple] = useState({});

  const handleChangeInput = (id: any, event: any) => {
    const newInputFields = inputFields.map((i: any) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        Code: '',
        Name: '',
        ClinicID: '',
        ClinicName: '',
        DateVaccine: '',
        BatchId: '',
        Country: '',
        Health: '',
      },
    ]);
  };

  const handleRemoveFields = (id: any) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex(value => value.id === id),
      1
    );
    setInputFields(values);
  };
  const onSimpleData = () => {
    let setData = inputFields.map((item: any) => {
      return {
        id: uuidv4(),
        Code: '1234567890123456',
        Name: 'PFIZER-BIONTECH COVID-19 Vaccine [Tozinameran] Injection',
        ClinicID: '[HCI000]',
        ClinicName: 'Vaccination site approved by Ministry of Health, Singapore [HCI000]',
        DateVaccine: '2021-07-13',
        BatchId: 'Lot12345',
        Country: 'Singapore',
        Health: 'Designated vaccinator by MOH-approved vaccination site [HCI000]',
      };
    });
    setInputFields(setData);
    setSimple({
      namePerson: 'David Zhang',
      NationalID: 'S9098989Z',
      PassportID: 'E7831177G',
      Nationality: 'Singaporean',
      dob: '2020-07-13',
      Gender: 'Male',
      phone: '0814206492',
      email: 'jakkaphan@everapp.io',
    });
  };

  return (
    <LayoutVaccineForm vaccineField={inputFields} SimpleData={onSimpleData} PatientData={simple} FormType='vaccine'>
      <Grid container className={classes.root} direction='row'>
        <Grid item xs={3}>
          <h3 style={{ color: '#325288' }}>Vaccine </h3>
        </Grid>
        <Grid item xs={9} style={{ marginTop: 80 }}>
          {inputFields.map(inputField => (
            <Grid container direction='column' justifyContent='center' item sm={8} key={inputField.id}>
              <Grid container direction='row'>
                <Grid item xs={10}>
                  <h4 style={{ color: '#9BADC8' }}>Vaccine Detail*</h4>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={handleAddFields} style={{ backgroundColor: '#FFA900' }}>
                    <Add style={{ color: 'white' }} />
                  </IconButton>
                </Grid>
              </Grid>

              <TextField
                name='Code'
                label='Code'
                variant='outlined'
                value={inputField.Code}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='Name'
                label='Name'
                variant='outlined'
                value={inputField.Name}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ClinicID'
                label='Clinic ID/Vaccination Centre ID'
                variant='outlined'
                value={inputField.ClinicID}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='ClinicName'
                label='Clinic/Vaccination Centre'
                variant='outlined'
                value={inputField.ClinicName}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='DateVaccine'
                label='Date Of Vaccine'
                variant='outlined'
                type='date'
                value={inputField.DateVaccine}
                onChange={event => handleChangeInput(inputField.id, event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name='BatchId'
                label='Batch Number'
                variant='outlined'
                value={inputField.BatchId}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='Country'
                label='Country/Region of Vaccination'
                variant='outlined'
                value={inputField.Country}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <TextField
                name='Health'
                label='Health Worker'
                variant='outlined'
                value={inputField.Health}
                onChange={event => handleChangeInput(inputField.id, event)}
              />
              <IconButton disabled={inputFields.length === 1} onClick={() => handleRemoveFields(inputField.id)}>
                <Delete />
              </IconButton>
              <hr />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </LayoutVaccineForm>
  );
};

export default CertificateForm;
