import React, { useState } from 'react';
import { Container, TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Send } from '@material-ui/icons';
import { UserLogin } from '../../store/action/UserAction';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: '10%',
    width: '80%',
  },
  imageStyle: {
    marginTop: '150px',
    // paddingLeft:'40%'
  },
}));
interface LoginInterface {
  setRole: Function;
}
const LoginForm: React.FC<LoginInterface> = ({ setRole }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setpassword] = useState('');
  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(UserLogin({ username, password, returnSecureToken: true }, setRole));
  };
  const onChangeUsername = (e: any) => setUsername(e.target.value);
  const onChangePassword = (e: any) => setpassword(e.target.value);
  return (
    <Container style={{ backgroundColor: 'white', borderColor: 'gray' }}>
      <form className={classes.root} onSubmit={handleSubmit}>
        <Grid
          container
          className={classes.root}
          direction='row'
          style={{ marginTop: 100, height: '70vh', backgroundColor: 'white', borderRadius: 10 }}
        >
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            lg={5}
            style={{ backgroundColor: '#0A3E98', textAlign: 'center', borderRadius: 10  }}
          >
            <img src={'./Image/android-chrome-192x192.png'} width={90} className={classes.imageStyle} alt='LogoEver' />
            <h3 style={{ color: 'whitesmoke' }}>Ever Certificate Issuer</h3>
          </Grid>
          <Grid
            item
            xs={7}
            sm={7}
            md={7}
            lg={7}
            style={{ paddingTop: '120px', paddingLeft: '10%', backgroundColor: '#F4F9F9', borderRadius: 10 }}
          >
            <Grid container direction='column' justifyContent='center' item xs={12} sm={8}md={8}lg={8} >
              <h1 style={{ color: '#325288', textAlign: 'center' }}>Log in</h1>
              <TextField
                name='username'
                label='Username '
                variant='outlined'
                value={username}
                onChange={onChangeUsername}
              />
              <TextField
                name='password'
                label='Password'
                variant='outlined'
                type='password'
                value={password}
                onChange={onChangePassword}
              />
              <Button className={classes.button} variant='contained' color='primary' type='submit' endIcon={<Send />}>
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <div style={{ marginTop: 20 }}></div>
    </Container>
  );
};

export default LoginForm;
