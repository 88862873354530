import React from 'react';
import TestCertificateForm from '../Form/TestCertificateForm';
import Layout from '../Layout';

const TestCertificate = () => {
  return (
    <Layout>
      <TestCertificateForm />
    </Layout>
  );
};

export default TestCertificate;
