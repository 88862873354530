import React from 'react';
import Header from './Header';
interface Layouts {
  needFooter?: boolean;
}
const Layout: React.FC<Layouts> = ({ children, needFooter }) => {
  let root = {
    flexGrow: 1,
    backgroundColor: '#F2F5FB',
    height: '100%',
  };
  return (
    <div>
      <Header />
      <div style={root}>{children}</div>
    </div>
  );
};

export default Layout;
