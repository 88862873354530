import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Send, Menu, ChevronLeft, ChevronRight, FolderShared, PersonPin } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import LocalStorageService from '../LocalStorageService/LocalStorageService';
import jwtDecode from 'jwt-decode';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imageStyle: {
    padding: 12,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 0.8,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: 10,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 250,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));
function Header() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [users, setUser]: any = useState({});
  const [open, setOpen] = useState(false);
  useEffect(() => {
    try {
      let Token = LocalStorageService.getToken();
      setUser(jwtDecode(Token));
    } catch (error) {
      window.location.reload();
    }
  }, []);
  const onClickLogout = () => {
    LocalStorageService.removeToken();
    window.location.reload();
  };
  const onClickIcon = () => {
    history.push('/');
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className={classes.root}>
      <AppBar position='static'>
        <Toolbar style={{ height: 100 }}>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={() => setOpen(!open)}
          >
            <Menu />
          </IconButton>
          <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='left'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleClose}>
                {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <div onClick={() => history.push(`/documents`)}>
                <ListItem button>
                  <ListItemIcon>
                    <FolderShared />
                  </ListItemIcon>
                  <ListItemText primary={'documents'} />
                </ListItem>
              </div>
            </List>
            <Divider />
            <List>
              <ListItem>
                <Hidden smUp>
                  <Button variant='contained' color='primary' endIcon={<Send />} onClick={onClickLogout}>
                    Logout
                  </Button>
                </Hidden>
              </ListItem>
            </List>
          </Drawer>
          <Typography onClick={onClickIcon} style={{ cursor: 'pointer' }}>
            <img src={'/Image/android-chrome-192x192.png'} width={65} className={classes.imageStyle} alt='LogoEver' />
          </Typography>
          <Hidden smDown>
            <Typography className={classes.title} onClick={onClickIcon} style={{ cursor: 'pointer' }} variant='h5'>
              Ever Certificate Issuer
            </Typography>
          </Hidden>
          <Typography>
            <PersonPin style={{ fontSize: 60, padding: 15, color: 'whitesmoke' }} />
          </Typography>
          <Hidden smDown>
            <Typography style={{ flexGrow: 0.1 }} variant='h6'>
              {users?.username}
            </Typography>
          </Hidden>
          <Hidden smDown>
            <Button variant='contained' color='primary' endIcon={<Send />} onClick={onClickLogout}>
              Logout
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Grid>
  );
}

export default Header;
