import React, { useEffect , useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FetchDocumnet } from '../../store/action/DocumentAction';
import { getData } from '@govtechsg/open-attestation';
import jwtDecode from 'jwt-decode';
import LocalStorageService from '../../LocalStorageService/LocalStorageService';
import {useHistory} from 'react-router-dom';
import axios from '../../Config/axios'
// components
import { DocumentPreview } from '../DocumentPreview';

// mui
import { Container, Grid, Button, makeStyles , CircularProgress , Backdrop } from '@material-ui/core';
import Layout from '../Layout';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));
const Preview = () => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const document = useSelector((state: any) => state.documents);
  const [open,setOpen] = useState(false)
  const token = LocalStorageService.getToken();
  const dataToken: any = jwtDecode(token);
  const history = useHistory()

  useEffect(() => {
    dispatch(FetchDocumnet(id));
  }, [id, dispatch]);
  const onApprove = (id: string) => {
    setOpen(true)
    axios.post('/v1/vaccine/approve',{documentId:id})
    .then((result) => {
      if (result.status === 200) history.push(`/documents/qrcode/${id}`, { status: "APPROVED" })
    }).catch((err) => {
        history.push('/errorpage')
    });
 
  };
  return (
    <Layout>
      <Container style={{ backgroundColor: 'white', borderColor: 'gray' }}>
        <Grid container className={classes.root} direction='row'>
          <Grid container item style={{ marginTop: 80 }}>
            <Grid container direction='column' justifyContent='center' item xs={8}></Grid>
            {dataToken.role === 'APPROVER' && (
              <Grid container item xs={3} justifyContent='center'>
                <Button variant='contained' color='primary' onClick={() => onApprove(id)}>
                  Approve
                </Button>
              </Grid>
            )}
            <Grid container>
              {document.data && (
                <div
                  style={{
                    maxWidth: '1280px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '1.5rem',
                    width: '100%',
                  }}
                >
                  <DocumentPreview document={getData(document)} />
                </div>
              )}
            </Grid>
            <div>
            <Backdrop className={classes.backdrop} open={open} >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Preview;
