import React,{useState} from 'react';
import {BrowserRouter} from 'react-router-dom'
import PrivateRoutes from './Config/PrivateRoutes';
import LocalStorageService from './LocalStorageService/LocalStorageService'
function App() {
  const [role,setRole] = useState(LocalStorageService.getRole())  
  return (
    <BrowserRouter>
    <PrivateRoutes role={role} setRole={setRole} />
    </BrowserRouter>
  );
}

export default App;
