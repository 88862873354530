import React from 'react';
import CertificateForm from '../Form/CertificateForm';
import Layout from '../Layout';

const Certificate = () => {
  return (
    <Layout>
      <CertificateForm />
    </Layout>
  );
};

export default Certificate;
