import Login from '../component/Auth/Login';
import Preview from '../component/Document/Preview';
import DocumentList from '../component/Document/DocumentList';
import ViewQrcode from '../component/Document/ViewQrcode';
import ErrorPage from '../component/Error';
import Success from '../component/Success';
import Certificate from '../component/Vaccine/Certificate';
import DocumentType from '../component/Vaccine/DocumentType';
import TestCertificate from '../component/Vaccine/TestCertificate';

const components = {
  login: {
    url: '/login',
    component: Login,
  },
  documentType: {
    url: '/',
    component: DocumentType,
  },
  certificate: {
    url: '/certificate',
    component: Certificate,
  },
  testCertificate: {
    url: '/testcertificate',
    component: TestCertificate,
  },
  success: {
    url: `/success`,
    component: Success,
  },
  error: {
    url: '/errorpage',
    component: ErrorPage,
  },
  documents: {
    url: '/documents',
    component: DocumentList,
  },
  viewqrcode: {
    url: '/documents/qrcode/:id',
    component: ViewQrcode,
  },
  preview: {
    url: '/documents/preview/:id',
    component: Preview,
  },
};
const userRole: any = {
  guest: {
    allowRoutes: [components.login],
    redirectRoutes: '/login',
  },
  CREATOR: {
    allowRoutes: [
      components.documentType,
      components.certificate,
      components.testCertificate,
      components.success,
      components.error,
      components.documents,
      components.viewqrcode,
      components.preview,
    ],
    redirectRoutes: '/',
  },
  APPROVER: {
    allowRoutes: [components.documents, components.preview, components.success, components.error,components.viewqrcode],
    redirectRoutes: '/documents',
  },
};
export default userRole;
