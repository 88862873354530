import React from 'react';
import { Grid } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';
import Header from './Header';
const Success = () => {
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Header />
      </Grid>
      <Grid item container xs={4} />
      <Grid
        item
        container
        xs={4}
        justifyContent='center'
        style={{ backgroundColor: 'white', borderRadius: 10, marginTop: 50, textAlign: 'center' }}
      >
        <Grid item xs={12} container justifyContent='center'>
          <div>
            <CheckOutlined
              style={{ color: 'white', backgroundColor: 'green', borderRadius: '50%', fontSize: 50, marginTop: 20 }}
            />
            <h2 style={{ color: 'green', textAlign: 'center' }}>Submitted</h2>
            <p>
              Your request has been submitted. The document and QR code will be available after your request is approved
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default Success;
